import '../css/footer.css';

export default function Footer() {
    return (
        < div className="footer" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-contact">
                            <h2>Contact Us</h2>
                            <a href="https://www.google.com/maps/place/Seoni,+Madhya+Pradesh+480661/@22.0868149,79.4742529,13z/data=!3m1!4b1!4m6!3m5!1s0x3a2ab003ceb2b715:0x307294a918b11bbb!8m2!3d22.0868691!4d79.5434841!16zL20vMDhoNjg0?entry=ttu&g_ep=EgoyMDI0MTAxMy4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer"><p><i className="fa fa-map-marker-alt"></i>SEONI, MP, India</p></a>
                            <a href="tel:+91-9479634713"><p><i className="fa fa-phone"></i>+91-9479634713</p></a>
                            <a href="mailto:support@koyanthevision.in"><p><i className="fa fa-envelope"></i>support@koyanthevision.in</p></a>
                            <div className="footer-social">
                                <a className="btn btn-custom" href="https://x.com/koyanthevision"><i className="fab fa-x-twitter"></i></a>
                                <a className="btn btn-custom" href="https://www.facebook.com/koyanthevision/"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-custom" href="https://www.instagram.com/koyan_the_vision/"><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-custom" href="https://www.youtube.com/channel/UC6bBebyItwSy4AXpGQ3bTAA"><i className="fab fa-youtube"></i></a>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-link">
                            <h2>Popular Links</h2>
                            <a href="/about">About Us</a>
                            <a href="/contact">Contact Us</a>
                            <a href="/event">Popular Causes</a>
                            <a href="/event">Upcoming Events</a>
                            <a href="#">Latest Blog</a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-link">
                            <h2>Useful Links</h2>
                            <a href="#">Terms of use</a>
                            <a href="#">Privacy policy</a>
                            <a href="#">Cookies</a>
                            <a href="#">Help</a>
                            <a href="#">FQAs</a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-newsletter">
                            <h2>Newsletter</h2>
                            <form name='NewsLetter'>
                                <input name='subscribe' className="form-control" placeholder="Email goes here" autoComplete='email' required/>
                                <button className="btn btn-custom">Submit</button>
                                <h6>Don't worry, we don't spam!</h6>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container copyright">
                <div className="row">
                    <div className="col-md-6">
                        <p>&copy; <a href="#">THE KOYAN THE VISION </a> | 2024, All Right Reserved.</p>
                    </div>
                    <div className="col-md-6">
                        <p>Designed By <a href="https://deepakmurape.000.pe/">DEEPAK MURAPE, Seoni</a></p>
                    </div>
                </div>
            </div>
        </ div>
    );
}